import * as Yup from "yup";
const phoneRegExp = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/i;
const email = Yup.string().email("Wrong email");
const phone = Yup.string().matches(phoneRegExp, "Phone number is not valid");
const requiredString = Yup.string().required("Required");

export const RequestAccessSchema = Yup.object().shape({
  name: requiredString,
  surname: requiredString,
  email: email.required("Required"),
  phone: phone,

  store: requiredString,
});

export const ForgotPasswordSchema = Yup.object().shape({
  username: requiredString,
  password: requiredString,
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both passwords need to be the same"
    ),
  }),
  code: requiredString,
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: requiredString,
  newPassword: requiredString.min(8, "Password must be at least 8 characters"),
});

export const AccountSchema = Yup.object().shape({
  "custom:email": email,
  "custom:phone": phone,
  "custom:billingEmail": email,
  "custom:billingPhone": phone,
});

export const ProductSchema = Yup.object().shape({
  color: Yup.string().required("Please select a color"),
});
